import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import Axios from 'axios'
import VueCookies from 'vue-cookies'
import humanizeDuration from 'humanize-duration'
import './registerServiceWorker'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSync, faTimesCircle, faCheckCircle, faPlus, faCog, faSignOutAlt, faQuestion, faSearch, faCaretDown, faCaretUp, faBan, faArrowRight, faExpand, faTimes, faClock, faHome, faBars, faSortAlphaDown, faSortAlphaUp, faSortNumericDown, faSortNumericUp, faRandom, faShieldAlt, faSlidersH, faStopwatch, faCloud, faEllipsisH, faCode, faAt, faServer, faCreditCard, faUserAlt, faLifeRing, faWrench, faTrash, faExchangeAlt, faFilter } from '@fortawesome/free-solid-svg-icons'
import { faChrome, faFirefoxBrowser } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Mutation } from './store/auth/types'

import VTooltip from 'v-tooltip'

Vue.use(VTooltip)

Vue.use(VueCookies)
 
library.add(faSync, faTimesCircle, faCheckCircle, faPlus, faCog, faSignOutAlt, faQuestion, faSearch, faCaretDown, faCaretUp, faBan, faArrowRight, faExpand, faTimes, faClock, faHome, faBars, faSortAlphaDown, faSortAlphaUp, faSortNumericDown, faSortNumericUp, faRandom, faShieldAlt, faSlidersH, faStopwatch, faCloud, faEllipsisH, faCode, faAt, faServer, faChrome, faFirefoxBrowser, faCreditCard, faUserAlt, faLifeRing, faWrench, faTrash, faExchangeAlt, faFilter)
 
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

Vue.prototype.$http = Axios;
if (store.getters.isLoggedIn) {
	store.commit(Mutation.AUTH_SUCCESS)
}

Vue.mixin({
	methods: {
		capitalize(value){
			return value.charAt(0).toUpperCase() + value.slice(1); 
		},
		resetCategoryTitle(){
			document.title = (this.$store.state.category.currentCategory.name === "all") ? "All Addresses - Mailpal" : this.capitalize(this.$store.state.category.currentCategory.name) + " - MailPal"
		},
		displayDate(value){
			if(value !== undefined && value !== 0){
				let date = new Date(value);
				let now = new Date();
				return humanizeDuration(now - date, { largest: 1, round: true }) + " ago";
			}else{
				return "-"
			}
		},
		copyToClipboard(value){
			const tempInput = document.createElement('INPUT');
			document.body.appendChild(tempInput);
			tempInput.setAttribute('value', value)
			tempInput.select();
			document.execCommand('copy');
			document.body.removeChild(tempInput);
		},
	}
});

Vue.directive('click-outside',{
	bind: function (el, binding, vnode) {
		el.eventSetDrag = function () {
			el.setAttribute('data-dragging', 'yes');
		}
		el.eventClearDrag = function () {
			el.removeAttribute('data-dragging');
		}
		el.eventOnClick = function (event) {
			var dragging = el.getAttribute('data-dragging');
			// Check that the click was outside the el and its children, and wasn't a drag
			if (!(el == event.target || el.contains(event.target)) && !dragging) {
				// call method provided in attribute value
				vnode.context[binding.expression](event);
			}
		};
		document.addEventListener('touchstart', el.eventClearDrag);
		document.addEventListener('touchmove', el.eventSetDrag);
		document.addEventListener('click', el.eventOnClick);
		document.addEventListener('touchend', el.eventOnClick);
	}, unbind: function (el) {
		document.removeEventListener('touchstart', el.eventClearDrag);
		document.removeEventListener('touchmove', el.eventSetDrag);
		document.removeEventListener('click', el.eventOnClick);
		document.removeEventListener('touchend', el.eventOnClick);
		el.removeAttribute('data-dragging');
	},
});

new Vue({
	store,
	router,
	render: h => h(App)
}).$mount('#app')
