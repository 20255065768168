export default {
    categories: [],
    status: 'loading',
    currentCategory: {
        name: "all",
        id: "all"
    },
    newCategory: {
        name: "",
        color: ""
    }
}