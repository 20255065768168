import axios from 'axios'
import { Action, Mutation } from './types'
import { Global_Mutation} from '../types'

export default {
    [Action.CHANGE_SILENT]({ commit }, value) {
		return new Promise((resolve, reject) => {
			axios({ url: '/api/app/options/silent?value=' + value, method: 'PUT' })
			.then(resp => {
				const response = resp.data;
				console.log(response);
				if(response.status === 200){
					commit(Mutation.SAVE_SILENT, value)
					resolve()
				}
			})
			.catch(err => {
				console.log(err.response)
				if(err.response.status == 401) {
					commit(Global_Mutation.REQUEST_ERROR, err.response.data.error);
					reject()
				}else{
					commit(Global_Mutation.REQUEST_ERROR, err);
					reject()
				}
			})
		})
    },
    [Action.GET_USER]({ commit }) {
		return new Promise((resolve, reject) => {
			axios({ url: '/api/app/user', method: 'GET' })
			.then(resp => {
				const response = resp.data;
				console.log(response);
				if(response.status === 200){
					commit(Mutation.SAVE_USER, response.result)
					resolve()
				}
			})
			.catch(err => {
				console.log(err.response)
				if(err.response.status == 401) {
					commit(Global_Mutation.REQUEST_ERROR, err.response.data.error);
					reject()
				}else{
					commit(Global_Mutation.REQUEST_ERROR, err);
					reject()
				}
			})
		})
	}
}