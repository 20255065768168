import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import { Mutation } from '../store/auth/types'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Base',
    component: () => import("@/views/Base"),
    meta: {
      scrollToTop: true 
    }
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: () => import("@/views/Login"),
    meta: {
      title: "Login - MailPal",
      scrollToTop: true 
    }
  },
  {
    path: '/signup',
    name: 'Sign Up',
    component: () => import("@/views/Notify"),
    meta: { 
      title: "Sign up - MailPal",
      requiresBeta: true,
      scrollToTop: true 
    }
  },
  {
    path: '/notify',
    name: 'Beta',
    component: () => import("@/views/Notify"),
    meta: { 
      title: "Get notified - MailPal",
      scrollToTop: true 
    }
  },
  {
    path: '/beta/signup',
    name: 'Beta Sign Up',
    component: () => import("@/views/SignUp"),
    meta: { 
      title: "Sign up - MailPal",
      scrollToTop: true 
    }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import("@/views/Settings"),
    meta: {
      title: "Settings - MailPal",
      requiresAuth: true,
      scrollToTop: true 
    }
  },
  {
    path: '/deactivate',
    name: 'Deactivate',
    component: () => import("@/views/Deactivate"),
    meta: {
      title: "Deactivate Address - MailPal",
      requiresAuth: true,
      scrollToTop: true 
    }
  },
  {
    path: '/docs',
    alias: '/documentation',
    name: 'Documentation',
    component: () => import("@/views/Docs"),
    meta: {
      title: "Docs - MailPal",
      scrollToTop: true 
    }
  },
  {
    path: '/landing',
    beforeEnter() { location.href = 'https://mailpal.cc/' },
  },
  {
    path: '/privacy',
    beforeEnter() { location.href = 'https://mailpal.cc/privacy' }
  },
  {
    path: '/inprint',
    beforeEnter() { location.href = 'https://mailpal.cc/inprint' },
  },
  {
    path: '*',
    name: '404',
    component: () => import("@/views/404.vue"),
    meta: {
      title: "Page not found - MailPal",
      scrollToTop: true 
    }
  }
]

const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) {
    return savedPosition
  } else {
    const position = {}
    if (to.hash) {
      position.selector = to.hash
      if (to.hash === '#anchor2') {
        position.offset = { y: 100 }
      }
    }
    if (to.matched.some(m => m.meta.scrollToTop)) {
      position.x = 0
      position.y = 0
    }
    return position
  }
}

const router = new VueRouter({
  mode: 'history',
  routes: routes,
  scrollBehavior 
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      store.commit(Mutation.AUTH_SUCCESS)
      return
    }
    next('/login') 
  } else if(to.matched.some(record => record.meta.requiresBeta)) {
    if (to.query.token === 'beta') {
      next('/beta/signup')
      return
    }
    next('/notify')
  } else {
    next() 
  } 
})

export default router
