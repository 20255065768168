import axios from 'axios'
import { Action, Mutation } from './types'

export default {
	[Action.GET_DOMAINS]({commit, state}) {
		return new Promise((resolve, reject) => {
			state.status = 'loading'
			axios({ url: '/api/app/domain', method: 'GET' })
			.then(resp => {
				const response = resp.data;
				console.log(response);
				if(response.status === 200){
					commit(Mutation.SAVE_DOMAINS, response.result)
					resolve(response.result)
				}
			})
			.catch(err => {
				console.log(err.response)
				if(err.response.status == 401) {
					commit(Mutation.AUTH_ERROR, err.response.data.error);
					reject()
				}else{
					commit(Mutation.AUTH_ERROR, err);
					reject()
				}
			})
		})
	},
	[Action.ADD_DOMAIN]({ commit, dispatch}, data) {
		return new Promise((resolve, reject) => {
			axios({ url: '/api/app/domain', data: { name:  data.name }, method: 'POST' })
			.then(resp => {
				const response = resp.data;
				console.log(response);
				if(response.status === 200){
					dispatch(Action.GET_DOMAINS_VERIFY)
					resolve()
				}
			})
			.catch(err => {
				console.log(err.response)
				if(err.response.status == 401) {
					commit(Mutation.AUTH_ERROR, err.response.data.error);
					reject()
				}else{
					commit(Mutation.AUTH_ERROR, err);
					reject()
				}
			})
		})
	},
	[Action.GET_DOMAINS_VERIFY]({commit, state}) {
		return new Promise((resolve, reject) => {
			state.status = 'loading'
			axios({ url: '/api/app/domain/verify', method: 'GET' })
			.then(resp => {
				const response = resp.data;
				console.log(response);
				if(response.status === 200){
					commit(Mutation.SAVE_DOMAINS, response.result)
					resolve(response.result)
				}
			})
			.catch(err => {
				console.log(err.response)
				if(err.response.status == 401) {
					commit(Mutation.AUTH_ERROR, err.response.data.error);
					reject()
				}else{
					commit(Mutation.AUTH_ERROR, err);
					reject()
				}
			})
		})
	},
	[Action.CHANGE_DOMAIN]({ commit, dispatch}, data) {
		return new Promise((resolve, reject) => {
			axios({ url: `/api/app/domain?id=${ data.id }&catchAll=${ data.catchAll }`, method: 'PUT' })
			.then(resp => {
				const response = resp.data;
				console.log(response);
				if(response.status === 200){
				dispatch(Action.GET_DOMAINS_VERIFY)
				resolve()
				}
			})
			.catch(err => {
				console.log(err.response)
				if(err.response.status == 401) {
					commit(Mutation.AUTH_ERROR, err.response.data.error);
					reject()
				}else{
					commit(Mutation.AUTH_ERROR, err);
					reject()
				}
			})
		})
	},
	[Action.DELETE_DOMAIN]({ commit, dispatch}, id) {
		return new Promise((resolve, reject) => {
			axios({ url: '/api/app/domain?id=' + id, method: 'DELETE' })
			.then(resp => {
				const response = resp.data;
				console.log(response);
				if(response.status === 200){
					dispatch(Action.GET_DOMAINS)
					resolve()
				}
			})
			.catch(err => {
				console.log(err.response)
				if(err.response.status == 401) {
					commit(Mutation.AUTH_ERROR, err.response.data.error);
					reject()
				}else{
					commit(Mutation.AUTH_ERROR, err);
					reject()
				}
			})
		})
	}
}