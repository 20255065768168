import Vue from "vue"
import Vuex from "vuex"
import auth from './auth'
import address from './address'
import category from './category'
import user from './user'
import domain from './domain'
import { Global_Mutation } from "./types"

Vue.use(Vuex)

function defaultState() {
    return {
        appVersion: process.env.VUE_APP_VERSION,
        authShow: false,
        status: "loading",
        showSideMenu: false,
        showBanner: false,
        mobile: window.innerWidth <= 1200,
        error: false,
        errorMsg: 'An error ocurred, please try again.'
    }
}

const state = defaultState()

export default new Vuex.Store({
    state: state,
    modules: {
        auth,
        address,
        category,
        user,
        domain
    },
    mutations: {
        [Global_Mutation.CHANGE_STATUS](state, status) {
            state.status = status;
        },
        [Global_Mutation.CHANGE_MOBILE](state, status) {
            state.mobile = status;
        },
        [Global_Mutation.CHANGE_SHOW_SIDE_MENU](state, status) {
            state.showSideMenu = status;
        },
        [Global_Mutation.CHANGE_SHOW_BANNER](state, status) {
            state.showBanner = status;
        }
    }
})