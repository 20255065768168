import axios from 'axios'
import { Action, Mutation } from './types'
import { Action as CategoryAction } from '../category/types'
import { Action as UserAction } from '../user/types'
import { Global_Mutation } from '../types'

export default {
    [Action.GET_ADDRESSES]({ commit }) {
        return new Promise((resolve, reject) => {
            axios({ url: '/api/app/address', method: 'GET' })
                .then(resp => {
                    const response = resp.data;
                    console.log(response);
                    if (response.status === 200) {
                        commit(Mutation.SAVE_ADDRESSES, response.result)
                        commit(Global_Mutation.CHANGE_STATUS, 'done')
                        resolve()
                    }
                })
                .catch(err => {
                    console.log(err.response)
                    if (err.response.status == 401) {
                        commit(Mutation.AUTH_ERROR, err.response.data.error);
                        reject()
                    } else {
                        commit(Mutation.AUTH_ERROR, err);
                        reject()
                    }
                })
        })
    },
    [Action.CREATE_ADDRESS]({ commit, dispatch }, data) {
        return new Promise((resolve, reject) => {
            axios({ url: '/api/app/address', data: { address: data.address, domain: data.domain, category: data.category, temporary: data.temporary }, method: 'POST' })
                .then(resp => {
                    const response = resp.data;
                    console.log(response);
                    if (response.status === 200) {
                        commit(Mutation.ADD_TO_ADDRESSES, response.result)
                        dispatch(CategoryAction.GET_CATEGORIES)
                        dispatch(UserAction.GET_USER)
                        commit(Mutation.RESET_NEW_ADDRESS)
                        resolve()
                    }
                })
                .catch(err => {
                    console.log(err.response)
                    if (err.response.status == 401) {
                        commit(Mutation.AUTH_ERROR, err.response.data.error);
                        reject()
                    } else {
                        commit(Mutation.AUTH_ERROR, err);
                        reject()
                    }
                })
        })
    },
    [Action.CHANGE_STATUS]({ commit, dispatch }, { id, active }) {
        return new Promise((resolve, reject) => {
            commit(Mutation.CHANGE_LOADING_ADDRESS, id)
            axios({ url: `/api/app/address?id=${id}&active=${active}`, method: 'PUT' })
                .then(resp => {
                    const response = resp.data;
                    console.log(response);
                    if (response.status === 200) {
                        dispatch(Action.GET_ADDRESSES)
                        commit(Mutation.CHANGE_LOADING_ADDRESS, undefined)
                        resolve()
                    }
                })
                .catch(err => {
                    console.log(err.response)
                    if (err.response.status == 401) {
                        commit(Mutation.AUTH_ERROR, err.response.data.error);
                        reject()
                    } else {
                        commit(Mutation.AUTH_ERROR, err);
                        reject()
                    }
                })
        })
    },
    [Action.DEACTIVATE_ADDRESS]({ commit }, address) {
        return new Promise((resolve, reject) => {
            axios({ url: `/api/app/address/deactivate`, method: 'POST', data: { address } })
                .then(resp => {
                    const response = resp.data;
                    console.log(response);
                    if (response.status === 200) {
                        resolve()
                    } else {
                        reject()
                    }
                })
                .catch(err => {
                    console.log(err.response)
                    if (err.response.status == 401) {
                        commit(Mutation.AUTH_ERROR, err.response.data.error);
                        reject()
                    } else {
                        commit(Mutation.AUTH_ERROR, err);
                        reject()
                    }
                })
        })
    },
    [Action.DELETE_ADDRESS]({ commit, dispatch }, id) {
        return new Promise((resolve, reject) => {
            axios({ url: `/api/app/address?id=${id}`, method: 'DELETE' })
                .then(resp => {
                    const response = resp.data;
                    console.log(response);
                    if (response.status === 200) {
                        commit(Mutation.REMOVE_FROM_ADDRESSES, id)
                        dispatch(CategoryAction.GET_CATEGORIES)
                        dispatch(UserAction.GET_USER)
                        resolve()
                    }
                })
                .catch(err => {
                    console.log(err.response)
                    if (err.response.status == 401) {
                        commit(Mutation.AUTH_ERROR, err.response.data.error);
                        reject()
                    } else {
                        commit(Mutation.AUTH_ERROR, err);
                        reject()
                    }
                })
        })
    },
    [Action.CHANGE_CATEGORY]({ commit, dispatch }, { id, category }) {
        return new Promise((resolve, reject) => {
            console.log(id + " - " + category)
            axios({ url: `/api/app/address?id=${id}&category=${category}`, method: 'PUT' })
                .then(resp => {
                    const response = resp.data;
                    console.log(response);
                    if (response.status === 200) {
                        dispatch(Action.GET_ADDRESSES)
                        dispatch(CategoryAction.GET_CATEGORIES)
                        resolve()
                    }
                })
                .catch(err => {
                    console.log(err.response)
                    if (err.response.status == 401) {
                        commit(Mutation.AUTH_ERROR, err.response.data.error);
                        reject()
                    } else {
                        commit(Mutation.AUTH_ERROR, err);
                        reject()
                    }
                })
        })
    },
}