import { Mutation } from './types'

export default {
    [Mutation.CHANGE_SHOW_SETTINGS](state, value){
        state.showSettings = value;
    },
    [Mutation.SAVE_USER](state, result){
        Object.keys(result).forEach(key => {
            state[key] = result[key];
        })
        state.loaded = true;
    },
    [Mutation.SAVE_SILENT](state, value){
        state.options.silent = value;
    }
    
}