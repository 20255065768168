import { Mutation } from './types'

export default {
    [Mutation.SAVE_CATEGORIES](state, result) {
        state.categories = result;
        state.status = 'done'
    },
    [Mutation.SWITCH_CATEGORY](state, id) {
        let find = state.categories.find(category => category._id === id);
        state.currentCategory = {
            id: id,
            name: (find === undefined) ? "all" : find.name
        }
    },
    [Mutation.UPDATE_NEW_CATEGORY_NAME](state, name) {
        state.newCategory.name = name;
    },
    [Mutation.UPDATE_NEW_CATEGORY_COLOR](state, color) {
        state.newCategory.color = color;
    },
    
}