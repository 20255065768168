export default {
    showSettings: "general",
    loaded: false,
    email: "",
    addresses: 0,
    categories: 0,
    options: {
        silent: false
    },
    stats: {
        forwarded: 0,
        blocked: 0,
        start: 0
    },
    quota: {
        addresses: 0,
        emails: 0
    },
    subscription: "free",
    created: 0,
    lastLogin: 0
}