import axios from 'axios'
import { Action, Mutation } from './types'
import { Action as AddressAction } from '../address/types'

export default {
    [Action.GET_CATEGORIES]({commit, state}) {
        return new Promise((resolve, reject) => {
            state.status = 'loading'
            axios({ url: '/api/app/category', method: 'GET' })
            .then(resp => {
              const response = resp.data;
              console.log(response);
              if(response.status === 200){
                  commit(Mutation.SAVE_CATEGORIES, response.result)
                  resolve()
              }
            })
            .catch(err => {
              console.log(err.response)
              if(err.response.status == 401) {
                  commit(Mutation.AUTH_ERROR, err.response.data.error);
                  reject()
              }else{
                  commit(Mutation.AUTH_ERROR, err);
                  reject()
              }
            })
          })
	},
	[Action.ADD_CATEGORY]({ state, commit, dispatch}) {
        return new Promise((resolve, reject) => {
			console.log(state.newCategory.name + " - " + state.newCategory.color)
            axios({ url: '/api/app/category', data: { name:  state.newCategory.name, color: state.newCategory.color }, method: 'POST' })
            .then(resp => {
              const response = resp.data;
              console.log(response);
              if(response.status === 200){
                dispatch(Action.GET_CATEGORIES)
                resolve()
              }
            })
            .catch(err => {
              console.log(err.response)
              if(err.response.status == 401) {
                  commit(Mutation.AUTH_ERROR, err.response.data.error);
                  reject()
              }else{
                  commit(Mutation.AUTH_ERROR, err);
                  reject()
              }
            })
          })
    },
    [Action.DELETE_CATEGORY]({ commit, dispatch}, id) {
		return new Promise((resolve, reject) => {
			axios({ url: '/api/app/category?id=' + id, method: 'DELETE' })
			.then(resp => {
				const response = resp.data;
				console.log(response);
				if(response.status === 200){
					dispatch(Action.GET_CATEGORIES)
					commit(Mutation.SWITCH_CATEGORY, "all")
					dispatch(AddressAction.GET_ADDRESSES)
					resolve()
				}
			})
			.catch(err => {
				console.log(err.response)
				if(err.response.status == 401) {
					commit(Mutation.AUTH_ERROR, err.response.data.error);
					reject()
				}else{
					commit(Mutation.AUTH_ERROR, err);
					reject()
				}
			})
		})
	}
}