import { Mutation } from './types'

export default {
    [Mutation.SAVE_ADDRESSES](state, result) {
        state.addresses = result;
    },
    [Mutation.ADD_TO_ADDRESSES](state, result) {
        state.addresses.push(result);
    },
    [Mutation.REMOVE_FROM_ADDRESSES](state, id) {
        console.log(id)
        let index = state.addresses.findIndex(e => e._id === id);
        if (index >= 1) {
            state.addresses.splice(index, 1);
        } else {
            console.error("nothing to remove")
        }
    },
    [Mutation.UPDATE_SEARCH](state, value) {
        state.search = value;
    },
    [Mutation.UPDATE_NEW_ADDRESS_SHOW](state, value) {
        state.showNewAddress = value;
    },
    [Mutation.RESET_NEW_ADDRESS](state) {
        state.showNewAddress = false
    },
    [Mutation.CLEAR_SEARCH](state) {
        state.search = "";
    },
    [Mutation.UPDATE_SORT_AFTER](state, value) {
        state.sort.after = value;
    },
    [Mutation.UPDATE_SORT_ORDER](state, value) {
        state.sort.order = value;
    },
    [Mutation.CHANGE_LOADING_ADDRESS](state, value) {
        state.loadingAddress = value;
    },
}