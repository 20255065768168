export const Action = {
    LOGIN: "LOGIN",
    SIGN_UP: "SIGN_UP",
    LOGOUT: "LOGOUT",
    CHANGE_ACCOUNT: "CHANGE_ACCOUNT",
    ACCOUNT_INFO: "ACCOUNT_INFO",
    CHECK_CODE: "CHECK_CODE"
};
export const Mutation = {
    LOGIN: "LOGIN",
    SIGN_UP: "SIGN_UP",
    VERIFY: "VERIFY",
    LOGOUT: "LOGOUT",
    CHANGE_EMAIL: "CHANGE_EMAIL",
    UPDATE_EMAIL: "UPDATE_EMAIL",
    UPDATE_CODE: "UPDATE_CODE",
    AUTH_REQUEST: "AUTH_REQUEST",
    AUTH_SUCCESS: "AUTH_SUCCESS",
    AUTH_ERROR: "AUTH_ERROR"
};