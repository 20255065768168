import axios from 'axios'
import { Action, Mutation } from './types'
import cookie from 'vue-cookies'

//import { Global_Mutation } from '../types'

export default {
    [Action.LOGIN]({ state, commit}) {
        return new Promise((resolve, reject) => {
            commit(Mutation.AUTH_REQUEST)
            axios({ url: '/api/auth/login', data: { email: state.email }, method: 'POST' })
            .then(resp => {
              console.log(resp)
              const response = resp.data;
              if(response.status === 200){
                  commit(Mutation.LOGIN)
                  resolve()
              }else{
                console.log(response.result)
                commit(Mutation.AUTH_ERROR, response.result);
              }
            })
            .catch(err => {
              console.log(err)
              commit(Mutation.AUTH_ERROR, err.response.data.result);
              reject()
            })
          })
    },
    [Action.SIGN_UP]({ state, commit}) {
      return new Promise((resolve, reject) => {
          commit(Mutation.AUTH_REQUEST)
          axios({ url: '/api/auth/signup', data: { email: state.email }, method: 'POST' })
          .then(resp => {
            console.log(resp)
            const response = resp.data;
            if(response.status === 200){
                commit(Mutation.SIGN_UP)
                resolve()
            }else{
              console.log(response.result)
              commit(Mutation.AUTH_ERROR, response.result);
            }
          })
          .catch(err => {
            console.log(err)
            commit(Mutation.AUTH_ERROR, err.response.data.result);
            reject()
          })
        })
    },
    [Action.CHECK_CODE]({ state, commit}, method) {
      return new Promise((resolve, reject) => {
          commit(Mutation.AUTH_REQUEST)
          axios({ url: '/api/auth/verify/' + method, data: { code: state.code }, method: 'POST' })
          .then(resp => {
            console.log(resp)
            const response = resp.data;
            if(response.status === 200){
              commit(Mutation.AUTH_SUCCESS)
              resolve()
            }else{
              console.log(response.result)
              commit(Mutation.AUTH_ERROR, response.result);
              reject()
            }
          })
          .catch(err => {
            console.log(err)
            commit(Mutation.AUTH_ERROR, err.response.data.error);
            reject()
          })
        })
    },
    [Action.LOGOUT]({commit}) {
        return new Promise((resolve) => {
            commit(Mutation.LOGOUT)
            cookie.remove('msuid'); //TODO: Send logout request
            location.reload()
            resolve()
          })
    }
}