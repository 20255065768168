export const Action = {
    GET_CATEGORIES: "GET_CATEGORIES",
    ADD_CATEGORY: "ADD_CATEGORY",
    DELETE_CATEGORY: "DELETE_CATEGORY"
};
export const Mutation = {
    SAVE_CATEGORIES: "SAVE_CATEGORIES",
    SWITCH_CATEGORY: "SWITCH_CATEGORY",
    UPDATE_NEW_CATEGORY_NAME: "UPDATE_NEW_CATEGORY_NAME",
    UPDATE_NEW_CATEGORY_COLOR: "UPDATE_NEW_CATEGORY_COLOR"
};