export const Action = {
    GET_ADDRESSES: "GET_ADDRESSES",
    CREATE_ADDRESS: "CREATE_ADDRESS",
    CHANGE_STATUS: "CHANGE_STATUS",
    DELETE_ADDRESS: "DELETE_ADDRESS",
    CHANGE_CATEGORY: "CHANGE_CATEGORY",
    DEACTIVATE_ADDRESS: "DEACTIVATE_ADDRESS"
};
export const Mutation = {
    SAVE_ADDRESSES: "SAVE_ADDRESSES",
    ADD_TO_ADDRESSES: "ADD_TO_ADDRESSES",
    REMOVE_FROM_ADDRESSES: "REMOVE_FROM_ADDRESSES",
    UPDATE_SEARCH: "UPDATE_SEARCH",
    UPDATE_NEW_ADDRESS: "UPDATE_NEW_ADDRESS",
    UPDATE_NEW_ADDRESS_CATEGORY: "UPDATE_NEW_ADDRESS_CATEGORY",
    UPDATE_NEW_ADDRESS_SHOW: "UPDATE_NEW_ADDRESS_SHOW",
    UPDATE_NEW_ADDRESS_TEMP: "UPDATE_NEW_ADDRESS_TEMP",
    RESET_NEW_ADDRESS: "RESET_NEW_ADDRESS",
    CLEAR_SEARCH: "CLEAR_SEARCH",
    UPDATE_SORT_AFTER: "UPDATE_SORT_AFTER",
    UPDATE_SORT_ORDER: "UPDATE_SORT_ORDER",
    CHANGE_LOADING_ADDRESS: "CHANGE_LOADING_ADDRESS"
};