import { Mutation } from './types'

export default {
    [Mutation.AUTH_REQUEST](state) {
        state.status = 'loading';
    },
    [Mutation.LOGIN](state) {
        state.status = 'verify'
        state.error = false;
    },
    [Mutation.SIGN_UP](state) {
        state.status = 'verify'
        state.error = false;
    },
    [Mutation.VERIFY](state) {
        state.status = 'subscription'
        state.error = false;
    },
    [Mutation.AUTH_ERROR](state, message) {
        console.log(message)
        console.log(typeof message)
        if(typeof message === 'string'){
            state.errorMsg = message;
        }
        state.status = 'error'
    },
    [Mutation.AUTH_SUCCESS](state) {
        state.status = 'success';
        state.error = false;
    },
    [Mutation.LOGOUT](state) {
        state.status = 'unauthorized'
    },
    [Mutation.UPDATE_EMAIL](state, value){
        state.email = value;
    },
    [Mutation.UPDATE_CODE](state, value){
        state.code = value;
    },
}